<template>
  <div
    class="border-opacity-25 textSize relative"
    :style="{
      width: width + 15 + 'px',
      height: height + 'px',

      opacity: 1,
    }"
  >
    <!--  -->
    <div style="padding: 6px">
      <div class="ml-2 mt-1">
        <div class="flex justify-between">
          <input
            class="iColor"
            type="color"
            v-model="color"
            :style="{ background: color }"
          />
          <div
            v-on:click="closeIt"
            style="width: 20%"
            class="absolute right-0 -top-3 mt-2 text-base text-sm px-2 text-red_ cursor-pointer"
          >
            x
          </div>
        </div>
        <div class="left_bg_tool mb-2 mt-0">{{ name.toUpperCase() }}</div>
      </div>
      <div class="mx-2">
        <div class="label_trend_prop uppercase mt-1">Name</div>
        <div class="label_trend_prop uppercase">
          <input
            class="editDescription"
            type="text"
            v-model="editedDescription"
          />
        </div>
        <div class="label_trend_prop uppercase mt-1 flex justify-between">
          Decimal <label class="ml-6">Alarm</label>
        </div>
        <div class="label_trend_prop uppercase mb-1 flex justify-around">
          <input class="scaleInput bg-gray-600" v-model="decimals" />

          <input
            class="scaleInput ml-2 __checkbox"
            type="checkbox"
            v-model="isAlert"
          />
        </div>

        <div class="label_trend_prop uppercase mt-1">Thickness</div>
        <div class="mr-1 tSelect text-center insideInputText">
          <CustomSelect
            :options="thickness"
            :default="tValue"
            @input="selectThickness($event)"
            class="th_cust_select trend_props"
            :class="{fromDIS: fromDIS}"
          />
        </div>
        <div v-if="units && curve && curve?.unit_category!='Miscellaneous'" class="label_trend_prop uppercase mt-1">Units</div>
        <div v-if="units && curve && curve?.unit_category!='Miscellaneous'" class="mr-1 tSelect text-center insideInputText">
          <CustomSelect
            :options="units.units"
            :default="selectedUnit"
            @input="selectUnithandler"
            class="th_cust_select trend_props"
            :class="{fromDIS: fromDIS}"
          />
        </div>
      </div>
      <div v-if="showManualScaleCheckbox"
        class="mx-2 mt-2 flex justify-between items-center"
      >
        <div class="label_trend_prop uppercase">Manual Scale</div>
        <input 
          v-model="manualScale"
          type="checkbox"
         />
      </div>  
      <div class="mx-2 mt-1">
        <div class="label_trend_prop uppercase mt-1">Scale</div>
        <div
          class="flex mr-1 items-center justify-between text-xs insideInputText"
        >
          <input
            class="scaleInput bg-gray-600"
            v-model="sLower"
            :class="disable_ranges ? 'disabledField' : ''"
            :disabled="disable_ranges"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="arrowSvg"
            width="14"
            height="7"
            viewBox="0 0 15 8"
            fill="none"
          >
            <path
              d="M14.8536 4.35355C15.0488 4.15829 15.0488 3.84171 14.8536 3.64645L11.6716 0.464466C11.4763 0.269204 11.1597 0.269204 10.9645 0.464466C10.7692 0.659728 10.7692 0.976311 10.9645 1.17157L13.7929 4L10.9645 6.82843C10.7692 7.02369 10.7692 7.34027 10.9645 7.53553C11.1597 7.7308 11.4763 7.7308 11.6716 7.53553L14.8536 4.35355ZM0 4.5H14.5V3.5H0V4.5Z"
              fill="#B4B8BE"
            />
          </svg>
          <input
            class="scaleInput bg-gray-600"
            v-model="sUpper"
            :class="disable_ranges ? 'disabledField' : ''"
            :disabled="disable_ranges"
          />
        </div>
      </div>
      <div class="mx-2">
        <div class="label_trend_prop uppercase mt-1">Alarm</div>
        <div
          class="flex items-center justify-between mr-1 insideInputText text-xs"
        >
          <input class="scaleInput" v-model="aLower" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="arrowSvg"
            width="14"
            height="7"
            viewBox="0 0 15 8"
            fill="none"
          >
            <path
              d="M14.8536 4.35355C15.0488 4.15829 15.0488 3.84171 14.8536 3.64645L11.6716 0.464466C11.4763 0.269204 11.1597 0.269204 10.9645 0.464466C10.7692 0.659728 10.7692 0.976311 10.9645 1.17157L13.7929 4L10.9645 6.82843C10.7692 7.02369 10.7692 7.34027 10.9645 7.53553C11.1597 7.7308 11.4763 7.7308 11.6716 7.53553L14.8536 4.35355ZM0 4.5H14.5V3.5H0V4.5Z"
              fill="#B4B8BE"
            />
          </svg>
          <input class="scaleInput" v-model="aUpper" />
        </div>
      </div>
      <div v-if="name == 'alert'" class="block justify-center my-2">
        <button
          v-on:click="toggle_height_handler_bha"
          :class="[
            'font-bold',
            'text-left',
            'w-11/12',
            'rounded',
            'td_btn',
            'alert_pop_btn',
          ]"
        >
          Pick Alerts
          <span v-if="!isExpandAlertDropdown"
            ><i class="fas fa-angle-down"></i
          ></span>
          <span v-else><i class="fas fa-angle-up"></i></span>
        </button>

        <ul
          v-if="isExpandAlertDropdown"
          v-bind:style="{
            height: isExpandAlertDropdown ? '' : '0px',
          }"
          class="alert_tip_pop text-xs p-0"
        >
          <li
            :key="index"
            v-for="(alert, index) in defaultAlertArr"
            class="top_ text-left"
          >
            <label
              :for="'radio_bha' + index"
              v-on:click="selectAlerts($event, alert)"
              :class="[
                'font-bold',
                'text-left',
                'w-full',
                'rounded',
                'td_btn',
                'flex',
                'mt-2',
              ]"
            >
              <div class="flex w-full text-white">
                {{ alert }}
                <input
                  type="checkbox"
                  :id="'radio_bha' + index"
                  :name="'radio_bha' + index"
                  :checked="alertArr.indexOf(alert) > -1"
                />
              </div>
            </label>
          </li>
        </ul>
      </div>
    </div>
    <div class="flex justify-between w-full mt-1">
      <div class="w-1/2">
        <button class="deleteBtn btnDS" @click="deleteCurve">DELETE</button>
      </div>
      <div class="w-1/2">
        <button class="saveBtn btnDS" @click="apply">SAVE</button>
      </div>
    </div>
  </div>
</template>

<script>
import CustomSelect from "../../Select/SelectComponent.vue";
import bin from "../../../assets/img/delete_1.png";
import check from "../../../assets/img/check.png";
import healperService from "../../../helper-services.js";
import { db } from "../../../db";
export default {
  name: "TrendProps",
  components: {
    CustomSelect,
  },

  data() {
    return {
      bin,
      check,
      thickness: [1, 2, 3, 4, 5, 6],
      updatedCurve: {},
      tValue: 2,
      sLower: "",
      sUpper: "",
      aLower: "",
      aUpper: "",
      color: null,
      name: "",
      isAlert: false,
      isExpandAlertDropdown: false,
      defaultAlertArr: [
        "ERRATIC MSE",
        "DRLG INEFF.",
        "ERRATIC ROP",
        "STACKING WEIGHT",
        "PRESSURE SPIKE",
        "BIT IMPACT",
        "MUD MOTOR DAMAGE POSSIBLE",
        "MUD MOTOR DAMAGE PROBABLE",
        "MOTOR STALL PROBABLE",
        "DIFF STICK",
      ],
      alertArr: this.alertArray,
      selectedAlerts: this.alertArray,
      decimals: 0,
      editedDescription: "",
      disable_ranges: false,
      units: null,
      selectedUnit: " ",
      manualScale: false,
    };
  },
  props: {
    height: [String, Number],
    width: [String, Number],
    curve: Object,
    alertArray: Array,
    fromDIS:{
      type: Boolean,
      default: false,
    },
    showManualScaleCheckbox:{
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    try {
      //get unit
      if (this.curve && this.curve.unit_category) {
        this.units = await healperService.getUnitCategories(
          this.curve.unit_category
        );
        this.selectedUnit = this.curve?.unit;
      }
      this.color = this.curve.color;
      this.name = this.curve.name;
      this.sLower =
        this.curve.scale[0] && typeof this.curve.scale[0] == "number"
          ? this.curve.scale[0].toFixed(2)
          : this.curve.scale[0];
      this.sUpper =
        this.curve.scale[1] && typeof this.curve.scale[1] == "number"
          ? this.curve.scale[1].toFixed(2)
          : this.curve.scale[1];
      this.tValue = this.curve.stroke ? this.curve.stroke : 2;
      this.aLower =
        this.curve.alert &&
        !isNaN(this.curve.alert[0]) &&
        typeof this.curve.alert[0] == "number"
          ? this.curve.alert[0].toFixed(2)
          : null;
      this.aUpper =
        this.curve.alert &&
        !isNaN(this.curve.alert[1]) &&
        typeof this.curve.alert[1] == "number"
          ? this.curve.alert[1].toFixed(2)
          : null;
      console.log("curvemounted", this.curve);
      this.decimals = this.curve.decimals;
      this.isAlert = this.curve.isAlert;
      this.editedDescription = Object.keys(this.curve).includes("editedName")
        ? this.curve.editedName
        : this.curve?.description;
      if (this.curve?.lwdTrackScale == "logarithmic") {
        this.disable_ranges = true;
      } else {
        this.disable_ranges = false;
      }
      if(this.curve.hasOwnProperty('scaleType')){
        if(this.curve.scaleType.replace(/\s/g, '').toLowerCase() == 'auto'){
          this.manualScale = false;
        }
        else{
          this.manualScale = true;
        }
      }
    } catch (error) {}
  },
  computed: {
    curve() {
      this.color = this.curve.color;
      this.name = this.curve.name;
      this.sLower = this.curve.scale[0]
        ? this.curve.scale[0].toFixed(2)
        : this.curve.scale[0];
      this.sUpper = this.curve.scale[1]
        ? this.curve.scale[1].toFixed(2)
        : this.curve.scale[1];
      this.tValue = this.curve.stroke ? this.curve.stroke : 2;
      return this.curve
    },
  },
  methods: {
    async selectUnithandler(newUnit) {
      if (newUnit != this.selectedUnit) {
        if(newUnit==this.curve.display_unit){
          let factDet = {
                unit_conversion_factor_id: 0,
                units_category_id: 0,
                from_unit:this.selectedUnit ,
                to_unit: newUnit,
                conversion_factor: 1,
              };
              this.$emit('conversion_factor',factDet,factDet);
              this.closeIt();
        }else{
          let displayUnitToCurrentUnit = {
                unit_conversion_factor_id: 0,
                units_category_id: 0,
                from_unit:this.selectedUnit ,
                to_unit: newUnit,
                conversion_factor: 1,
              };
          await db.unitfactors
          .where("units_category_id")
          .equals(this.units.units_category_id)
          .each((fact) => {
            if (
              fact.from_unit == this.curve.display_unit &&
              fact.to_unit == newUnit
            ) {
              displayUnitToCurrentUnit = fact;
            }
          });
        await db.unitfactors
          .where("units_category_id")
          .equals(this.units.units_category_id)
          .each((fact) => {
            console.log('fact.from_unit == this.selectedUnit && fact.to_unit == newUnit::', fact.from_unit == this.selectedUnit &&
              fact.to_unit == newUnit,this.selectedUnit,newUnit,'unicatid=:',this.units.units_category_id,'factor::',fact);
            if (
              fact.from_unit == this.selectedUnit &&
              fact.to_unit == newUnit
            ) {
              this.$emit('conversion_factor',fact, displayUnitToCurrentUnit);
              this.closeIt();
            }
          });
        }
      }
    },
    toggle_height_handler_bha() {
      this.isExpandAlertDropdown = !this.isExpandAlertDropdown;
    },
    deleteCurve() {
      this.$emit("delete");
    },
    closeIt() {
      this.$emit("closeIt");
    },
    selectAlerts(e, alert) {
      let selectedAlerts = this.selectedAlerts;
      if (e.target.checked) {
        selectedAlerts.push(alert);
      } else {
        selectedAlerts.splice(selectedAlerts.indexOf(alert), 1);
      }

      this.selectedAlerts = selectedAlerts;
    },
    apply() {
      this.updatedCurve["color"] = this.color;
      this.updatedCurve["thickness"] = this.tValue;
      this.updatedCurve["scaleMin"] = this.sLower;
      this.updatedCurve["scaleMax"] = this.sUpper;
      this.updatedCurve["alertMin"] = this.aLower;
      this.updatedCurve["alertMax"] = this.aUpper;
      this.updatedCurve["decimals"] = this.decimals;
      this.updatedCurve["isAlert"] = this.isAlert;
      this.updatedCurve["editedDescription"] = this.editedDescription;
      if(this.showManualScaleCheckbox){
        this.updatedCurve["scaleType"] = this.manualScale ? 'manual' : 'auto';
      }
      if (this.name == "alert") {
        this.updatedCurve["alertsArr"] = this.selectedAlerts;
      }

      this.$emit("apply", this.updatedCurve);
    },
    selectThickness(thickness) {
      this.tValue = thickness;
    },
  },
  watch: {
    alertArray(newValue) {
      this.alertArr = newValue;
    },
  },
};
</script>
<style scoped>
.__checkbox {
  background-color: transparent !important;
  width: 15px !important;
  height: 15px !important;
}
.iColor {
  background: transparent;
  height: 10px;
  width: 10px;
  border-radius: 0px;
}

.textSize {
  font-size: 12px;
  margin-bottom: 1px;
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15);
  background-color: var(--widgetBg);
}

.scaleInput {
  width: 30px;
  height: 15px;
  /* border: 1px solid gray; */
  margin-right: 0.5px;
  font-size: 1.5vh;
  text-align: center;
  /* background-color: #2a2a2a; */
  background: var(--colorBoxPopUpInputBg);
}

.tSelect {
  width: 100%;
  height: 15px;
  /* border: 1px solid gray; */
  margin-right: 4px;
  color: black;
  background-color: var(--colorBoxPopUpInputBg);
}

.items-table div {
  padding: 0px 0px !important;
  height: -13px !important;
  /* position: inherit; */
  /* width: 100%; */
  color: black;
}

.del_ico {
  margin: 0 auto;
  width: 20px;
  cursor: pointer;
}

.button_ {
  display: flex;
  padding: 0 10px;
  width: 100%;
  margin: 0 5px;
  height: 100%;
  vertical-align: middle;
  align-self: center;
}

.button_img {
  margin: 1px 3px;
  width: 14px;
  padding: 1px;
  cursor: pointer;
}

.text-red_ {
  color: #cf634b;
  font-size: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.text-base {
  font-size: 13px;
}

.left_bg_tool {
  width: 80%;
  word-break: break-all;
  margin-bottom: 0;
  font-size: 10px;
  font-weight: 600;
  color: var(--colorBoxPopUpHeadingColor);
}

.text-white {
  color: white;
}

.pop_track_tool {
  background: #29292f;
  margin-bottom: 9px;
  padding: 3px;
}

.alert_tip_pop {
  position: absolute;
  background: #28282f;
  top: 0px;
  font-size: calc((0.2em + 0.2vmin) + (0.2em + 0.2vmax));
  height: 200px;
  overflow-y: auto;
}

.alert_pop_btn {
  display: flex;
  justify-content: space-between;
  padding: 0 6px;
  color: white;
}

.th_cust_select {
  width: 100%;
}
.arrowSvg {
  margin-left: 2px;
  margin-right: 2px;
}
.label_trend_prop {
  color: var(--colorBoxHeadingText);
  font-size: 9px;
}
.deleteBtn {
  /* background: var(--colorBoxPopUpDeleteBg); */
  background: var(--widgetBg);
  color: var(--colorBoxPopUpDeleteText);
  /* border: 1px solid #fff; */
}
.saveBtn {
  /* background: var(--colorBoxPopUpSaveBg); */
  background: var(--colorBoxBg);
  color: #fff;
}
.btnDS {
  padding: 4px 8px;
  font-size: 9px;
  width: 100%;
  color: var(--colorBoxHeadingText);
}
.insideInputText {
  color: var(--selectingTimeFeetColor);
}
.editDescription {
  font-size: 1.5vh;
  background: var(--colorBoxPopUpInputBg);
  padding: 0px 4px;
  width: 100%;
  height: 15px;
  font-weight: 100;
}
.disabledField {
  opacity: 0.6;
}
</style>
